import React, { useState, useEffect } from "react";
// import usePlacesAutocomplete,{
//   getGeocode,
//   getLatLng,
//   getDetails,
// } from "use-places-autocomplete";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails,
} from "use-places-autocomplete";
import { toast } from "react-toastify";
import useOnclickOutside from "react-cool-onclickoutside";
// import styles from "./RideBook.module.css";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
// import searchLogo from "../../shared/images/search.png";

// import ClearIcon from '@mui/icons-material/Clear';
const Autocomplete = (props) => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [formattedAddress, setFormatedAddress] = useState("");
  const [Erorr, setErorr] = useState("");
  // const [toggle, settoggle] = useState(false)
  const [placeType, setPlaceType] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [isready, setIsReady] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "se" },
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    const newValue = e.target.value;

    // Reset input value
    setValue(newValue);

    // Also reset editedData.addressLine1 when input is empty
    if (newValue === "") {
      props.setEditedData((prev) => ({
        ...prev,
        addressLine1: "",
      }));
    }

    if (props.setPostalCode) {
      props.setPostalCode(""); // ✅ Reset postal code when address is cleared
    }
  };

  const handleSelect =
    ({ description }) =>
    async () => {
      setIsLoading(true);
      setValue(description, false);

      const selected_data = data.find((ele) => ele.description === description);

      try {
        const detail = await getDetails({ placeId: selected_data.place_id });

        // 🔹 Extract Postal Code from Address Components
        let postalCode = "";
        detail.address_components.forEach((component) => {
          if (component.types.includes("postal_code")) {
            postalCode = component.long_name; // Extract Postal Code
          }
        });

        const { lat, lng } = detail.geometry.location;
        setLocation({
          latitude: lat(),
          longitude: lng(),
        });

        setFormatedAddress(description);
        setErorr("");

        // 🔹 Set Address in props.setEditedData.addressLine1
        if (props.setEditedData) {
          props.setEditedData((prev) => ({
            ...prev,
            addressLine1: description,
          }));
        }

        // 🔹 Send Postal Code to Parent Component (If Needed)
        if (props.setPostalCode) {
          props.setPostalCode(postalCode.replace(/\s/g, ""));
        }
      } catch (error) {
        console.error("Error fetching place details:", error);
      }

      clearSuggestions();
      setIsLoading(false);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          key={place_id}
          // className={styles.placesList}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}, </strong>
          <span className="text-secondary">{secondary_text}</span>
        </div>
      );
    });
  useEffect(() => {
    props.passLocation(location);
    props.passFormattedAddress(formattedAddress, Erorr);
  }, [location, formattedAddress]);

  return (
    <div ref={ref}>
      <div style={{ display: props.isDisplayLocation, position: "relative" }}>
        <FormControl fullWidth variant="outlined">
          <TextField
            variant="outlined"
            autoComplete="off"
            placeholder={props.placeholder}
            id="outlined-adornment-pickup"
            value={value || props.editedData?.addressLine1 || ""}
            onChange={handleInput}
            aria-describedby="outlined-pickup-helper-text"
            inputProps={{
              "aria-label": "pickup",
            }}
          />
        </FormControl>
      </div>

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <div className={`card shadow m-0 p-0`}>
          <div className="card-body places-card m-0 p-0">
            {renderSuggestions()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
