import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Divider, Grid, CircularProgress, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetReturnedProductsQuery } from '../../redux/Apis/Order'; // Adjust the import based on your project structure
import './Returns.css'; // Import your CSS for styling
import { Trash } from 'lucide-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddCard from './AddCard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SavedCards = () => {
    const { isLoggedIn, userId } = useSelector((state) => state.authSlice);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false); // State to control dialog visibility
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();

    const getUserCards = async () => {
        try {
            const response = await fetch(`${BASE_URL}/payment/get-user-cards`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch cards');
            }

            const data = await response.json();
            setCards(data.paymentMethods || []);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCard = async (cardId) => {
        try {
            const response = await fetch(`${BASE_URL}/payment/delete-user-card`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, paymentMethodId: cardId }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete card');
            }

            toast.success('Kortet har raderats!');
            getUserCards(); // Refresh the cards
        } catch (error) {
            console.error('Delete error:', error);
            toast.error('Error deleting card.');
        }
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        getUserCards(); // Refresh the cards after adding a new one
    };

    useEffect(() => {
        if (userId) {
            getUserCards();
        }
    }, [userId]);

    return (
        <div className='my-order-container' style={{ padding: '20px' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                Mina kort
            </Typography>

            {/* Add Card Button */}
            <Button variant="contained" color="primary" onClick={handleOpenDialog} sx={{ mb: 2 }}>
                Lägg till kort
            </Button>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                    <CircularProgress />
                </div>
            ) : cards.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#757575' }}>
                        Du har inga sparade kort ännu.
                    </Typography>
                </div>
            ) : (
                <Grid container spacing={5}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={12} key={index}>
                            <Card
                                variant="outlined"
                                sx={{
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        {card.card.brand}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
                                        **** **** **** {card.card.last4}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.875rem' }}>
                                        {card.card.exp_month}/{card.card.exp_year}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                        <IconButton onClick={() => handleDeleteCard(card.id)} sx={{ color: '#f44336' }}>
                                            <Trash size={20} />
                                        </IconButton>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}

            {/* Add Card Dialog */}
            <Dialog open={open} onClose={handleCloseDialog} fullWidth>
                <DialogContent sx={{padding: "3rem 0"}}>
                    <Elements stripe={stripePromise}>
                        <AddCard onClose={handleCloseDialog} />
                    </Elements>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Avboka
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
};

export default SavedCards;