import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({ onClose }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!stripe || !elements) return;

            setIsLoading(true);
            setMessage('');

            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                redirect: "if_required", // Avoids unnecessary redirects
            });

            if (error) {
                throw new Error(error.message);
            } else {
                toast.success('Card saved successfully!');
                onClose();
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
            <h2>Spara ditt kort</h2>

            <div style={{ marginBottom: '20px' }}>
                <PaymentElement options={{ layout: "tabs" }} />
            </div>

            <Button type='submit' variant="contained" className='me-3'  disabled={!stripe || isLoading}>
            {isLoading ? 'Saving...' : 'Save Card'}
            </Button>
            <Button onClick={onClose} color="secondary">
                        Avboka
            </Button>

            {message && (
                <div style={{ marginTop: '20px', color: message.startsWith('Error') ? 'red' : 'green' }}>
                    {message}
                </div>
            )}
        </form>
    );
};


const AddCard = ({onClose}) => {
    const [clientSecret, setClientSecret] = useState("");
    const { isLoggedIn, userId } = useSelector((state) => state.authSlice);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_BASE_URL}/payment/create-setup-intent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.clientSecret) {
                    setClientSecret(data.clientSecret);
                } else {
                    console.error("Failed to get client secret:", data);
                }
            });
    }, []);

    if (!clientSecret) return <p className="text-white text-center">Loading...</p>;

    return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm onClose={onClose} />
        </Elements>
    );
};

export default AddCard;