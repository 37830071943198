import React, { useEffect, useState } from "react";
import "./Cart.css";
import {
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CartTable from "./CartTable"; // Component to display the cart items in table format
import CartTotal from "./CartTotal"; // Component to display total price and actions
import LoginForm from "./LoginForm"; // Separate LoginForm Component
import SignupForm from "../Auth/SignupForm"; // Separate SignupForm Component
import { useTranslation } from "react-i18next";
import { useGetRandomQuery } from "../../redux/Apis/Products";
import Cookies from "js-cookie";
import ProductSlider from "../Trending/ProductSlider";

const CartContainer = () => {
  const nav = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const cartItems = useSelector((state) => state.cartSlice.items); // Get cart items from Redux store
  const { t } = useTranslation();
  const [open, setOpen] = useState(false); // State to control the modal visibility
  const [tabIndex, setTabIndex] = useState(0); // State to control which tab is selected

  const {
    data: randomProductsResponse,
    error,
    isLoading,
  } = useGetRandomQuery();
  const randomProducts = randomProductsResponse?.data || []; // Ensure it's an array

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const isCookieConsentAccepted = useSelector(
    (state) => state.cookieConsent.isAccepted
  );

  useEffect(() => {
    if (isCookieConsentAccepted) {
      // Retrieve the main recently viewed product IDs cookie
      let productIds = [];
      const mainCookie = Cookies.get("recentlyViewedProducts");
      if (mainCookie) {
        try {
          productIds = JSON.parse(mainCookie);
        } catch (e) {
          console.error("Error parsing main cookie:", e);
        }
      }

      // Ensure productIds is an array
      if (!Array.isArray(productIds)) {
        productIds = []; // Reset to an empty array if data is malformed
      }

      // Load each product's data from its individual cookie
      const recentlyViewedProducts = productIds
        .map((id) => {
          const productCookie = Cookies.get(`recentlyViewedProduct_${id}`);
          return productCookie ? JSON.parse(productCookie) : null;
        })
        .filter((product) => product !== null);

      // Set the recently viewed products state with the retrieved data
      setRecentlyViewedProducts(recentlyViewedProducts);
    }
  }, [isCookieConsentAccepted]);

  const handleCheckout = () => {
    // if (!isLoggedIn) {
    //     setOpen(true); // Show modal if not logged in
    // } else {
    nav("/auth/checkout"); // Proceed to checkout if logged in
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className="container ">
      {/* Header Section */}
      <div className="checkout-nav text-center ">
        <h1 className="checkout-heading">{t("ShopCart")}</h1>
      </div>

      <div className="row">
        {/* Cart Items Section */}
        <div className="col-lg-8 mb-4">
          <div className="card p-4">
            <h4 className="section-heading">{t("CartItems")}</h4>
            <hr className="my-3" />
            <CartTable cartItems={cartItems} />{" "}
            {/* Pass cart items to CartTable */}
          </div>
        </div>

        {/* Cart Total Section */}
        <div className="col-lg-4 mb-4">
          <div className="card p-4">
            <CartTotal cartItems={cartItems} />{" "}
            {/* Pass cart items to CartTotal */}
            <Button
              variant="contained"
              className="btn btn-primary mt-3 w-100"
              onClick={handleCheckout}
              disabled={cartItems.length === 0} // Disable if no items in cart
            >
              Fortsätt till kassan
            </Button>
          </div>
        </div>
      </div>

      {/* Product Related Section */}
      <div className="product-related-section mb-5">
        <h2 className="mb-4 text-center">{t("CusAlso")}</h2>
        <ProductSlider
          products={
            recentlyViewedProducts.length === 1
              ? randomProducts
              : isCookieConsentAccepted
              ? recentlyViewedProducts
              : randomProducts
          }
        />
      </div>

      {/* Sign In / Sign Up Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle className="text-center">
          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Sign In" />
            <Tab label="Sign Up" />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "300px" }}
          >
            {tabIndex === 0 ? <LoginForm /> : <SignupForm />}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CartContainer;
