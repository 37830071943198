import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Checkbox,
  FormControl,
  colors,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateCartMutation,
  useGetCartQuery,
  useDeleteCartMutation,
  useShippingStatusMutation,
} from "../../redux/Apis/Cart/index";
import { useTranslation } from "react-i18next";
import ShippingAddressForm from "./ShippingAddressForm";
import OrderDetails from "./OrderDetails";
import "./CheckoutCart.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCreditCard from "./StripeCreditCard"; // Assuming you have this component
import {
  useCheckUserOrdersMutation,
  useCreateOrderMutation,
} from "../../redux/Apis/Order";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import loadgif from "../../shared/images/loadinggif.gif";
import { ArrowBigLeft } from "lucide-react";
import { useGetApplyKupongMutation } from "../../redux/Apis/Coupon";
import {
  useAddUserAddressMutation,
  useDeleteUserAddressMutation,
  useGetUserAddressesQuery,
  useUpdateUserAddressMutation,
} from "../../redux/Apis/User";
import Loader from "../Loader/Loader";
import { useGetLatestExchangeRateQuery } from "../../redux/Apis/Exchange";
import { toast } from "react-toastify";
import { clearCart } from "../../redux/Slices/Cart/index";
import AutoComplete from "../Account/Autocomplete";

const CheckoutCart = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [addAddress, { isLoading: isAdding, isError: isAddingError }] =
    useAddUserAddressMutation();
  const cartItems = useSelector((state) => state.cartSlice.items);
  const { isLoggedIn, userId, user } = useSelector((state) => state.authSlice);
  const {
    data: cartApiResponse,
    refetch,
    isLoading,
    isError,
  } = useGetCartQuery(userId, { skip: !isLoggedIn || !userId });
  const cartData = cartApiResponse?.data;
  const [orginalPrice, setOriginalPrice] = useState(0);
  const APIKEY = process.env.REACT_APP_GOOGLE_API;

  const {
    data: shippingAddresses,
    refetch: refetchShippingAddresses,
    isLoading: isShippingLoading,
    isError: isShippingError,
  } = useGetUserAddressesQuery(userId, {
    skip: !isLoggedIn || !userId, // अगर logged in नहीं है तो API call skip होगी
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
  });
  const [applyKupong, { data, error, isLoadingKupong }] =
    useGetApplyKupongMutation();
  const [createCart] = useCreateCartMutation();
  const [deleteCart] = useDeleteCartMutation();
  const [createShipping] = useShippingStatusMutation();
  const [cartDeleted, setCartDeleted] = useState(false);
  const [createOrder] = useCreateOrderMutation();
  const [checkOrder, { dataCheck, error2, isLoadingCheck }] =
    useCheckUserOrdersMutation();
  // State for payment processing
  const [clientSecret, setClientSecret] = useState("");
  const [showPayment, setShowPayment] = useState(false); // New state to control payment visibility

  const {
    data: dataExchange,
    errorExchange,
    isLoadingExchange,
  } = useGetLatestExchangeRateQuery();
  const [rate, setRate] = useState(null); // State to store the rate
  useEffect(() => {
    if (dataExchange?.success) {
      setRate(dataExchange.rate.rate); // Store the rate in state
    }
  }, [dataExchange]); // Run effect when dataExchange changes

  // Shipping address and selected shipping state
  const [shippingAddress, setShippingAddress] = useState({
    addressLine1: "",
    postalCode: "",
    country: "se",
    phone: "",
  });
  const [formFields, setFormFields] = useState({
    email: "",
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    postal_code: "",
    country: "se",
    phone: "",
  });
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [discount, setDiscount] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [
    updateUserAddress,
    { isLoading: isUpdating, isError: isUpdatingError },
  ] = useUpdateUserAddressMutation();
  const [deleteUserAddress, { isLoading: isDeleting, isError: isDeleteError }] =
    useDeleteUserAddressMutation();
  const [updateAddress, setUpdateAddress] = useState({
    addressLine1: "",
    postalCode: "",
    phone: "",
  });
  const [selectedCard, setSelectedCard] = useState(null);
  const [cheapest, setCheapeast] = useState(null);
  const [saveForNextTime, setSaveForNextTime] = useState(false);
  const [fomatedPickUpAdress, setfomatedPickUpAdress] = useState("");
  const [showViaLocationInput, setShowViaLocationInput] = useState(false);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: ["places"],
  });

  const handlePickupLocation = (data) => {
    setPickupLocation(data);
  };

  const handleViaLocationInput = () => {
    if (!showViaLocationInput) {
      setShowViaLocationInput(true);
    } else {
      setShowViaLocationInput(false);
    }
  };

  const handlePickUpFormattedAddress = (data, Erorr) => {
    setfomatedPickUpAdress(data);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await checkOrder({ userId });

        setFirstTime(response.data?.hasOrders);
      } catch (error) {
        console.error("Error checking orders:", error);
      }
    };

    isLoggedIn && fetchOrders();
  }, [userId, checkOrder]);

  const payload = useMemo(
    () => ({
      userId,
      items: cartItems.map((item) => ({
        productId: item.productId,
        sku: item.sku,
        quantity: item.quantity,
        scale: item.size,
        isBigBuyProduct: item.isBigBuyProduct,
      })),
    }),
    [userId, cartItems]
  );

  const shippingPayload = useMemo(
    () => ({
      cart: {
        userId,
        items: cartItems.map((item) => ({
          sku: item.sku,
          quantity: item.quantity,
          scale: item.size,
          isBigBuyProduct: item.isBigBuyProduct,
        })),
      },
      isoCountry: isLoggedIn ? shippingAddress.country : formFields.country,
      postcode: isLoggedIn
        ? shippingAddress.postalCode
        : formFields.postal_code,
    }),
    [userId, cartItems, shippingAddress, formFields]
  );

  const handleInputChange = (field, value) => {
    setFormFields((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };

  useEffect(() => {
    const deleteExistingCart = async () => {
      if (userId && cartData?._id) {
        await deleteCart(cartData._id).unwrap();
        setCartDeleted(true);
      } else {
        setCartDeleted(true);
      }
    };
    deleteExistingCart();
  }, [userId, cartData, deleteCart]);

  useEffect(() => {
    const createNewCart = async () => {
      if (userId && cartItems.length > 0 && cartDeleted) {
        try {
          await createCart(payload).unwrap();
          await refetch();
        } catch (error) {
          // Handle error
        }
      }
    };
    createNewCart();
  }, [userId, cartItems, createCart, refetch, cartDeleted, payload]);

  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    console.log("useEffect triggered", { formFields, shippingAddress });
    console.log(
      !isLoggedIn &&
        formFields?.postal_code?.length === 5 &&
        formFields?.country
    );
    const createShippingRequest = async () => {
      setLoading(true); // Start loading
      try {
        const response = await createShipping(shippingPayload).unwrap();

        setShippingOptions(response?.shippingOptions || []);
        setCheapeast(response?.cheapestShippingOptions || []);
      } catch (error) {
        console.error("Error creating shipping request:", error);
        setShippingOptions([]);
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
    };

    if (
      !isLoggedIn &&
      formFields?.postal_code?.length === 5 &&
      !!formFields?.country
    ) {
      createShippingRequest();
    }

    // Check if the postal code length is 5 and country exists
    if (shippingAddress?.postalCode?.length === 5 && shippingAddress.country) {
      createShippingRequest();
    } else {
      setShippingOptions([]);
      if (showPayment) {
        setShowPayment(false);
        setClientSecret(""); // Hide payment component and go back to shipping
      }
    }
  }, [createShipping, formFields, shippingAddress]);

  // Function to handle selecting a shipping option
  const handleSelectShippingOption = (event) => {
    // Get the selected shipping option object
    const selectedOption = shippingOptions.find(
      (option) => option.shippingService.id === event.target.value
    );
    setSelectedShippingOption(selectedOption); // Set the full object
  };

  // Function to handle navigation back to the cart
  const handleGoBackToCart = () => {
    nav("/cart");
  };

  const [savedCards, setSavedCards] = useState([]);
  const [showExisiting, setShowExisinf] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(null);

  const validateForm = () => {
    let newErrors = {};

    if (!formFields.first_name.trim()) {
      newErrors.first_name = "First Name is required";
    }
    if (!formFields.last_name.trim()) {
      newErrors.last_name = "Last Name is required";
    }
    if (!formFields.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formFields.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formFields.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formFields.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formFields.addressLine1.trim()) {
      newErrors.addressLine1 = "Address is required";
    }
    if (!formFields.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!formFields.postal_code.trim()) {
      newErrors.postal_code = "Postal code is required";
    } else if (!/^\d{5,6}$/.test(formFields.postal_code)) {
      newErrors.postal_code = "Invalid postal code";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (!selectedShippingOption) {
      setErrors({ shipping: "Please select a shipping option." });

      return; // Early return if no shipping option is selected
    }

    if (isLoggedIn) {
      const totalAmount = parseFloat(totalPrice); // Assuming totalPrice is in currency units
      try {
        const test = totalAmount.toFixed(2) * 100;

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/payment/create-payment-intent`,
          {
            amount: parseInt(totalAmount),
            userId: userId, // Add userId to the request body
          }
        );

        setClientSecret(response.data.clientSecret);
        setShowPayment(true); // Show payment component
      } catch (error) {}
    } else {
      if (!validateForm()) {
        // Check if validation fails
        toast.error("Fyll i alla fält");
        return;
      }
      const totalAmount = parseFloat(totalPrice); // Assuming totalPrice is in currency units
      try {
        const test = totalAmount.toFixed(2) * 100;

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/payment/create-anonymous-payment-intent/`,
          {
            amount: parseInt(totalAmount),
            userDetails: {
              email: formFields.email,
              first_name: formFields.first_name,
              last_name: formFields.last_name,
              phone: formFields.phone,
              address: formFields.address,
            },
          }
        );

        setClientSecret(response.data.clientSecret);
        setShowPayment(true); // Show payment component
      } catch (error) {}
    }
  };

  const handleNextExisitng = async () => {
    if (!selectedShippingOption) {
      setErrors({ shipping: "Please select a shipping option." });

      return; // Early return if no shipping option is selected
    }

    const totalAmount = parseFloat(totalPrice); // Assuming totalPrice is in currency units
    setShowExisinf(true);

    try {
      const test = totalAmount.toFixed(2) * 100;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payment/create-payment-intent`,
        {
          amount: parseInt(totalAmount),
          userId: userId, // Add userId to the request body
        }
      );

      setPaymentIntentId(response.data.paymentIntentId);
      setClientSecret(response.data.clientSecret);
      setShowPayment(true); // Show payment component
    } catch (error) {}
  };

  const handleBack = () => {
    // Optionally, you can also hide the payment component if you're in the payment step
    if (showPayment) {
      setShowPayment(false);
      setClientSecret(""); // Hide payment component and go back to shipping
    }

    // If needed, clear any errors
    setErrors({});
  };

  const [couponCode, setCouponCode] = useState("");

  const [isCouponApplied, setIsCouponApplied] = useState(false); // New state for tracking coupon application

  const handleApplyCoupon = async () => {
    const products = cartItems
      .filter((item) => item.isBigBuyProduct)
      .map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
      }));

    if (!couponCode) {
      alert("Please enter a coupon code.");
      return;
    }

    try {
      const response = await applyKupong({
        code: couponCode,
        products: products, // Send the products array with productId and quantity
        userId: userId,
      }).unwrap();

      if (response.success && response.data.isValid) {
        setCouponCode(couponCode);
        setDiscount(response.data.totalDiscount.toFixed(2) || 0);
        setIsCouponApplied(true); // Set to true when coupon is successfully applied
      } else {
        setDiscount(0);
        setIsCouponApplied(false); // Reset if coupon is invalid
      }
    } catch (error) {
      console.error("Error applying discount:", error);
      setDiscount(0);
      setIsCouponApplied(false); // Reset on error
    }
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getUserCards = async () => {
    try {
      const response = await fetch(`${BASE_URL}/payment/get-user-cards`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch cards");
      }

      const data = await response.json();
      setCards(data.paymentMethods || []); // Assuming the response contains a `cards` field
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    isLoggedIn && getUserCards();
  }, [userId]);

  const totalPriceCount = () => {
    let total = 0;
    const shippingCost = selectedShippingOption
      ? selectedShippingOption.cost || 0 // Use the full object directly
      : 0;
    cartItems.map((item) => {
      total += item.price * item.quantity;
    });
    return (total + shippingCost).toFixed(2);
  };

  const totalPrice = useMemo(() => {
    const basePrice = cartData?.totalPrice || 0;
    const shippingCost = selectedShippingOption
      ? selectedShippingOption.cost || 0 // Use the full object directly
      : 0;

    const originalPriceTotat = basePrice + shippingCost;
    setOriginalPrice(originalPriceTotat.toFixed(2));

    // Use `firstTime` to determine the additional discount
    const existingDiscount = Number(discount) || 0;
    const additionalDiscount = !firstTime ? basePrice * 0.1 : 0; // Apply 10% additional discount if firstTime is false

    const totalDiscount = existingDiscount + additionalDiscount;

    const discountedPrice = basePrice + shippingCost - totalDiscount;

    return discountedPrice > 0 ? discountedPrice.toFixed(2) : "0.00";
  }, [
    cartData,
    selectedShippingOption,
    discount,
    firstTime,
    setFirstTime,
    isLoadingCheck,
  ]);

  // if (isShippingLoading) {
  //   return (
  //     <div>
  //       <Loader />
  //     </div>
  //   );
  // }

  if (isShippingError) {
    return <div>Error loading shipping addresses.</div>;
  }

  const addresses = shippingAddresses?.data;

  const handleAddNewAddress = () => {
    setSelectedAddress(null);
    // Reset the shippingAddress for a new entry
    setShippingAddress({
      addressLine1: "",
      postalCode: "",
      country: "se",
      phone: "",
    });

    setIsEditMode(false);
    setUpdateAddress({ addressLine1: "", postalCode: "", phone: "" });
    setEditDialogOpen(true);
  };

  const handleSelectAddress = (address) => {
    setShippingAddress({
      addressLine1: address?.addressLine1,
      postalCode: address?.postalCode,
      country: address?.country,
      phone: address?.phone,
    });
    setSelectedAddress(address);
  };

  const handlePayNow = async () => {
    try {
      setLoader(true);

      if (!termsAccepted) {
        toast.info("Vänligen acceptera villkoren och sekretesspolicyn");
        return;
      }

      if (saveForNextTime) {
        await addAddress({
          userId,
          addressLine1: shippingAddress.addressLine1,
          postalCode: shippingAddress.postalCode,
          phone: shippingAddress.phone,
          country: "se",
        });
      }

      // Send payment confirmation request
      const response = await fetch(
        `${BASE_URL}/payment/confirm-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            paymentIntentId,
            paymentMethodId: selectedCardId,
          }),
        }
      );

      if (!response.ok) {
        const errorMessage = `Payment failed with status: ${response.status}`;
        toast.error(errorMessage);
        return;
      }

      const data = await response.json();
      const { paymentIntent: { id: paymentId, status: paymentStatus } = {} } =
        data;

      const orderPayload = {
        userDetails: {
          email: shippingAddress.email,
          phone: shippingAddress.phone,
          first_name: shippingAddress.first_name,
          last_name: shippingAddress.last_name,
          address: shippingAddress.addressLine1,
          city: shippingAddress.city,
          postal_code: shippingAddress.postalCode,
          country: "se",
        },
        items: cartData?.items.map((item) => ({
          productId: item.productId,
          totalItemPrice: item.totalItemPrice,
          quantity: item.quantity,
          price: item.price,
        })),
        shippingOption: selectedShippingOption,
        totalAmount: totalPrice,
        cheapestOptions: cheapest,
        paymentId,
        paymentStatus,
        paymentMethod: "card",
      };

      let responseOrder;
      if (userId) {
        // Logged-in user
        responseOrder = await createOrder(orderPayload);
      } else {
        // Anonymous user
        const anonymousResponse = await fetch(
          `${BASE_URL}/orders/create-anonymous`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(orderPayload),
          }
        );

        if (!anonymousResponse.ok) {
          toast.error("Failed to create anonymous order. Please try again.");
          return;
        }

        responseOrder = await anonymousResponse.json();
      }

      if (responseOrder.error) {
        toast.error("Det gick inte att skapa beställning. Försök igen.");
        return;
      }

      dispatch(clearCart());
      await deleteCart(cartData?._id).unwrap();

      const orderDetails = encodeURIComponent(JSON.stringify(responseOrder));
      nav(`/auth/order-success?orderDetails=${orderDetails}`, {
        replace: true,
      });
      toast.success("Beställning skapad framgångsrikt!");

      setShowExisinf(false);
    } catch (error) {
      console.error("Error during payment confirmation:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  const handleCardSelection = (cardId, cardDetails) => {
    setSelectedCardId(cardId);
    setSelectedCard(cardDetails);
  };

  const handleGoBack = () => {
    setSelectedCardId(null);
    setSelectedCard(null);
  };

  const handlePlaceSelect = (place) => {
    // Set the address line 1 based on the place selected
    const addressLine1 = place?.structured_formatting?.main_text;
    setUpdateAddress((prev) => ({
      ...prev,
      addressLine1,
    }));
  };

  if (loader)
    return (
      <div>
        <Loader />
      </div>
    );

  // Run when the component mounts or userId changes

  const handleCardSelect = (id) => {
    setSelectedCardId(id);
  };

  const handleSelectAddress2 = (address) => {
    // Extract address components and concatenate them into a full address
    const fullAddress = address.address_components
      .map((component) => component.long_name) // Extract the long name from each component
      .join(", "); // Join all parts with commas

    // Set the full address in the state
    setUpdateAddress((prevState) => ({
      ...prevState,
      addressLine1: fullAddress, // Set the full address
    }));
  };

  const handleEditAddress = (address) => {
    setShippingAddress({
      addressLine1: "",
      postalCode: "",
      country: "se",
      phone: "",
    });
    setIsEditMode(true);
    setSelectedShippingOption(null);
    setSelectedAddress(null);
    setUpdateAddress(address);
    setEditDialogOpen(true);
  };

  const handleDeleteAddress = (addressId) => {
    setShippingAddress({
      addressLine1: "",
      postalCode: "",
      country: "se",
      phone: "",
    });
    setSelectedShippingOption(null);
    setSelectedAddress(null);
    setUpdateAddress(addressId);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = () => setEditDialogOpen(false);
  const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

  const handleSaveEditedAddress = async () => {
    if (updateAddress) {
      try {
        if (isEditMode) {
          // Edit Address API Call
          await updateUserAddress({
            userId: userId,
            addressId: updateAddress._id,
            addressLine1: updateAddress.addressLine1,
            postalCode: updateAddress.postalCode,
            phone: updateAddress.phone,
          }).unwrap();
        } else {
          // Add New Address API Call
          await addAddress({
            userId,
            addressLine1: updateAddress.addressLine1,
            postalCode: updateAddress.postalCode,
            phone: updateAddress.phone,
            country: "se",
          });
        }

        // Reset and close the dialog
        setUpdateAddress(null);
        setEditDialogOpen(false);
        refetchShippingAddresses();
      } catch (error) {
        console.error("Failed to save address:", error);
        // Optionally, handle the error (e.g., show a notification)
      }
    }
  };

  const handleConfirmDelete = () => {
    if (updateAddress) {
      // Ensure elem.id exists
      deleteUserAddress({ userId, addressId: updateAddress })
        .unwrap() // If you want to handle the response
        .then(() => {
          // Refetch the shipping addresses after a successful delete
          refetchShippingAddresses();
        })
        .catch((error) => {
          console.error("Error deleting address:", error);
        });
    }
    setDeleteDialogOpen(false);
  };

  const commonAddressForm = () => {
    return (
      <>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            fullWidth
            placeholder={t("First Name")}
            variant="outlined"
            name="first_name"
            value={formFields.first_name}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            error={!!errors.first_name}
            helperText={errors.first_name}
          />
          <TextField
            fullWidth
            placeholder={t("Last Name")}
            variant="outlined"
            name="last_name"
            value={formFields.last_name}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
            error={!!errors.last_name}
            helperText={errors.last_name}
          />
        </Box>
        <TextField
          fullWidth
          placeholder={"E-post"}
          variant="outlined"
          name="email"
          value={formFields.email}
          onChange={(e) => handleInputChange("email", e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          fullWidth
          placeholder={"Telefonnummer"}
          variant="outlined"
          name="phone"
          value={formFields.phone}
          onChange={(e) => handleInputChange("phone", e.target.value)}
          error={!!errors.phone}
          helperText={errors.phone}
        />
        <AutoComplete
          placeholder="Adress"
          passLocation={handlePickupLocation}
          addStop={false}
          handleViaLocationInput={handleViaLocationInput}
          passFormattedAddress={handlePickUpFormattedAddress}
          setPostalCode={(postal_code) =>
            setFormFields((prev) => ({
              ...prev,
              postal_code: postal_code.replace(/\s/g, ""),
            }))
          }
          value={formFields?.addressLine1}
          setEditedData={setFormFields}
          editedData={formFields}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            fullWidth
            placeholder={"Stad"}
            variant="outlined"
            value={formFields.city}
            name="city"
            error={!!errors.city}
            helperText={errors.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
          />
          <TextField
            fullWidth
            placeholder={"Postnummer"}
            variant="outlined"
            value={formFields.postal_code}
            onChange={(e) => handleInputChange("postal_code", e.target.value)}
            name="postal_code"
            error={!!errors.postal_code}
            helperText={errors.postal_code}
          />
        </Box>
      </>
    );
  };

  return (
    <div className="checkout-container">
      <div className="checkout-left">
        <div className="user-info d-flex">
          <p className="user-greeting">Hej</p>
          {isLoggedIn && (
            <h1 className="user-name">
              {user?.first_name} {user?.last_name}
            </h1>
          )}
          {!isLoggedIn && (
            <Button
              size="small"
              variant="text"
              color="primary"
              sx={{ textTransform: "none", marginLeft: "auto" }}
              onClick={() => nav("/auth/login")}
            >
              Login
            </Button>
          )}
        </div>
        {!firstTime && (
          <div
            className="discount-message"
            style={{
              marginBottom: "20px",
              color: "#28a745",
              fontWeight: "bold",
            }}
          >
            Du får 10% rabatt på din första beställning!
          </div>
        )}
        {!isLoggedIn && commonAddressForm()}
        <>
          {isLoggedIn && addresses && addresses.length > 0 ? (
            <div>
              <h3>Välj en befintlig leveransadress</h3>
              <div className="address-cards">
                <FormControl component="fieldset">
                  <RadioGroup
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    {addresses?.map((addr) => (
                      <Box
                        key={addr._id}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid lightgray",
                          borderRadius: 1,
                          padding: 1,
                        }}
                      >
                        <FormControlLabel
                          // value={addr.id}
                          control={
                            <Radio
                              checked={selectedAddress?._id === addr?._id}
                              onChange={() => handleSelectAddress(addr)}
                              sx={{ "&.Mui-checked": { color: "black" } }}
                            />
                          }
                          label={
                            <Box>
                              <Typography>{`${addr.addressLine1}`}</Typography>
                              <Typography>{`${addr.postalCode}`}</Typography>
                              <Typography>{`${addr.phone}`}</Typography>
                            </Box>
                          }
                          sx={{ marginRight: "auto" }}
                        />

                        <Button
                          size="small"
                          variant="text"
                          color="primary"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleEditAddress(addr)}
                        >
                          Redigera
                        </Button>
                        <Button
                          size="small"
                          variant="text"
                          color="error"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleDeleteAddress(addr._id)}
                        >
                          Radera
                        </Button>
                      </Box>
                    ))}
                  </RadioGroup>
                </FormControl>
                <Button
                  className="mt-2"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    handleAddNewAddress();
                  }}
                >
                  Lägg till ny adress
                </Button>
              </div>
            </div>
          ) : (
            <>
              {isLoggedIn && (
                <div>
                  <h3>Inga leveransadresser hittades.</h3>
                  <AutoComplete
                    placeholder="Adress"
                    passLocation={handlePickupLocation}
                    addStop={false}
                    handleViaLocationInput={handleViaLocationInput}
                    passFormattedAddress={handlePickUpFormattedAddress}
                    setPostalCode={(postalCode) =>
                      setShippingAddress((prev) => ({ ...prev, postalCode }))
                    }
                    value={shippingAddress?.addressLine1}
                    setEditedData={setShippingAddress}
                    editedData={shippingAddress}
                  />
                  {/* <TextField
                    label="Adress"
                    fullWidth
                    margin="normal"
                    value={shippingAddress?.addressLine1 || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only 5 digits
                      if (/^\d{0,5}$/.test(value)) {
                        setShippingAddress((prev) => ({
                          ...prev,
                          addressLine1: value,
                        }));
                      }
                    }}
                    inputProps={{
                      maxLength: 5, // Limit the input to 5 characters
                    }}
                  /> */}
                  <TextField
                    label="Postnummer"
                    fullWidth
                    margin="normal"
                    value={shippingAddress?.postalCode || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only 5 digits
                      if (/^\d{0,5}$/.test(value)) {
                        setShippingAddress((prev) => ({
                          ...prev,
                          postalCode: value,
                        }));
                      }
                    }}
                    inputProps={{
                      maxLength: 5, // Limit the input to 5 characters
                    }}
                  />
                  <TextField
                    label="Telefon"
                    fullWidth
                    margin="normal"
                    value={shippingAddress?.phone || ""}
                    onChange={(e) =>
                      setShippingAddress((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setSaveForNextTime(!saveForNextTime)}
                        name="saveForNextTime"
                        color="primary"
                      />
                    }
                    label="Spara denna information till nästa gång"
                  />
                </div>
              )}
            </>
          )}

          <Dialog
            open={editDialogOpen}
            onClose={handleCloseEditDialog}
            style={{ zIndex: 1000 }}
          >
            <DialogTitle>
              {isEditMode ? "Redigera adress" : "Lägg till adress"}
            </DialogTitle>
            <DialogContent>
              {/* Google Places Autocomplete for Address Line 1 with custom styles */}
              <AutoComplete
                placeholder="Adress"
                passLocation={handlePickupLocation}
                addStop={false}
                handleViaLocationInput={handleViaLocationInput}
                passFormattedAddress={handlePickUpFormattedAddress}
                setPostalCode={(postalCode) =>
                  setUpdateAddress((prev) => ({ ...prev, postalCode }))
                }
                value={updateAddress?.addressLine1}
                setEditedData={setUpdateAddress}
                editedData={updateAddress}
              />
              {/* Other address fields */}
              <TextField
                label="Postnummer"
                fullWidth
                margin="normal"
                value={updateAddress?.postalCode || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only 5 digits
                  if (/^\d{0,5}$/.test(value)) {
                    setUpdateAddress((prev) => ({
                      ...prev,
                      postalCode: value,
                    }));
                  }
                }}
                inputProps={{
                  maxLength: 5, // Limit the input to 5 characters
                }}
              />

              <TextField
                label="Telefon"
                fullWidth
                margin="normal"
                value={updateAddress?.phone || ""}
                onChange={(e) =>
                  setUpdateAddress((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
              />
              {isUpdatingError && <p>Error updating address.</p>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog} color="primary">
                Avboka
              </Button>
              <Button
                onClick={handleSaveEditedAddress}
                color="primary"
                disabled={isUpdating}
              >
                {isUpdating ? "Sparande..." : "Spara"}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Bekräfta radering</DialogTitle>
            <DialogContent>
              <Typography>
                Är du säker på att du vill ta bort den här adressen?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Avboka
              </Button>
              <Button onClick={handleConfirmDelete} color="secondary">
                Radera
              </Button>
            </DialogActions>
          </Dialog>
        </>

        <OrderDetails
          cartData={isLoggedIn ? cartData : cartItems}
          isLoggedIn={isLoggedIn}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleGoBackToCart}
          className="go-back-button"
        >
          {t("GoBackCart")}
        </Button>
      </div>

      <div className="checkout-right">
        {showPayment ? (
          <></>
        ) : (
          <>
            <div className="apply-coupon">
              <h4 className="coupon-title">Använd kupong</h4>
              <div className="coupon-input-container">
                <input
                  type="text"
                  placeholder={t("Ange din kupongkod")}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="coupon-input"
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleApplyCoupon}
                  className="apply-coupon-button"
                  disabled={isCouponApplied} // Disable if the coupon was successfully applied
                >
                  {t("Apply")}
                </Button>
              </div>
            </div>
          </>
        )}
        {showPayment ? (
          <>
            <div className="back-div">
              <Button
                onClick={() => {
                  handleBack();
                }}
                className="back-button"
              >
                <ArrowBigLeft />
                Frakt
              </Button>
              <h4 className="checkout-title">{t("PayOptions")}</h4>
            </div>

            <Divider className="divider" />

            {showExisiting ? (
              <>
                <Container>
                  <Typography variant="h5" gutterBottom>
                    Dina kort
                  </Typography>

                  {/* Display selected card */}
                  {selectedCard ? (
                    <Box mb={2}>
                      <Typography variant="h6">Valt kort</Typography>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h6">
                            {selectedCard.card.brand}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            **** **** **** {selectedCard.card.last4}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {selectedCard.card.exp_month}/
                            {selectedCard.card.exp_year}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGoBack}
                            fullWidth
                            style={{ marginTop: "10px" }}
                          >
                            Byt kort
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  ) : (
                    <></>
                  )}

                  {/* Display available cards if none is selected */}
                  {!selectedCard && cards.length > 0 ? (
                    <Grid container spacing={2}>
                      {cards.map((card, index) => (
                        <Grid item xs={12} key={index}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h6">
                                {card.card.brand}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                **** **** **** {card.card.last4}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {card.card.exp_month}/{card.card.exp_year}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleCardSelection(card.id, card)
                                }
                                fullWidth
                                style={{ marginTop: "10px" }}
                              >
                                Välj kort
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <>{selectedCard ? <></> : <p>Inga kort hittades</p>}</>
                  )}

                  {/* Pay Now Button */}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handlePayNow(selectedCardId)}
                    disabled={!selectedCardId} // Disable button if no card is selected
                    fullWidth
                    style={{ marginTop: "20px" }}
                  >
                    Betala nu
                  </Button>

                  {/* Button to add a new card */}
                  {isLoggedIn && (
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => setShowExisinf(false)}
                      style={{ marginTop: "10px" }}
                    >
                      Ange nytt kort
                    </Button>
                  )}
                </Container>
              </>
            ) : (
              <>
                {clientSecret && (
                  <Elements
                    stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)}
                    options={{ clientSecret }}
                  >
                    <StripeCreditCard
                      clientSecret={clientSecret}
                      amount={totalPrice}
                      userId={userId}
                      createOrder={createOrder} // Assuming you have this function
                      cartItems={cartData?.items || []}
                      shippingAddress={shippingAddress}
                      cartID={cartData?._id}
                      cards={savedCards}
                      shippingOption={selectedShippingOption}
                      usedCoupang={couponCode}
                      cheapestOptions={cheapest}
                    />
                  </Elements>
                )}
                {isLoggedIn && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowExisinf(true)}
                  >
                    Använd ett befintligt kort
                  </Button>
                )}
              </>
            )}

            <div className="cart-pricing">
              <p>{t("Total")}</p>
              <p>{`${totalPrice} kr`}</p>
            </div>
          </>
        ) : (
          <>
            <h4 className="checkout-title">{t("Shipping Options")}</h4>
            <Divider className="divider" />
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <img
                  src={loadgif}
                  alt="Loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            ) : (
              <RadioGroup
                value={selectedShippingOption?.shippingService.id} // Ensure selectedShippingOption is the full object
                onChange={handleSelectShippingOption}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {shippingOptions.length > 0 ? (
                  shippingOptions.map((option) => (
                    <Card
                      key={option.shippingService.id}
                      style={{
                        cursor: "pointer",
                        transition: "0.3s",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.boxShadow =
                          "0 4px 20px rgba(0, 0, 0, 0.2)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.boxShadow =
                          "0 2px 10px rgba(0, 0, 0, 0.1)")
                      }
                    >
                      <CardContent
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FormControlLabel
                          value={option.shippingService.id}
                          control={<Radio color="primary" />}
                          label={
                            <div style={{ flex: 1 }}>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                {option.shippingService.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ color: "gray" }}
                              >
                                {option.shippingService.delay}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: "bold" }}
                              >
                                {option.cost.toFixed(2)} kr
                              </Typography>
                            </div>
                          }
                        />
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <p>Inga fraktalternativ tillgängliga</p>
                )}
              </RadioGroup>
            )}
            {errors.shipping && <p className="error">{errors.shipping}</p>}
            {discount > 0 && (
              <div className="cart-pricing">
                <p>Kupong Dicount</p>
                <p>{discount} kr</p>
              </div>
            )}
            {firstTime === false && (
              <div className="cart-pricing">
                <p>Total Before Discount</p>
                <p>{`${orginalPrice} kr`}</p>
              </div>
            )}
            <div className="cart-pricing">
              <p>{t("Total")}</p>
              <p>{`${isLoggedIn ? totalPrice : totalPriceCount()} kr`}</p>
            </div>
            {isLoggedIn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleNextExisitng();
                }}
                className="next-button"
                disabled={!selectedShippingOption}
              >
                Använd ett befintligt kort
              </Button>
            )}
            {isLoggedIn && (
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedShippingOption}
                onClick={() => {
                  handleNext();
                }}
                className="next-button"
              >
                Betala med nytt kort
              </Button>
            )}

            {!isLoggedIn && <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I approve Swedebuy's{" "}
                  <Link
                    href="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1876D1", textDecoration: "underline" }}
                  >
                    terms and conditions
                  </Link>{" "}
                  of purchase and{" "}
                  <Link
                    href="/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    style={{ textDecoration: "underline", color: "#1876D1" }}
                  >
                    privacy policy
                  </Link>{" "}
                  (required).
                </Typography>
              }
            />}

            {!isLoggedIn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleNext();
                }}
                className="next-button"
                disabled={!selectedShippingOption}
              >
                Betala nu
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(CheckoutCart);
