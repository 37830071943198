import { ThemeProvider } from "@emotion/react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../redux/Apis/User";
import { StandardInputTheme } from "./StandardInputTheme";
import "./style.css"; // Assuming you have a CSS file for styling
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Checkbox, FormControlLabel, FormHelperText, Typography } from "@mui/material";

const SignupForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signup, { isSuccess }] = useRegisterMutation();

  // Destructure getValues from useForm
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const maxDate = dayjs().subtract(13, "years"); // User must be at least 13 years old

  useEffect(() => {
    if (isSuccess) {
      toast.success("Registrering framgångsrikt");
      navigate("/email-sent");
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    const values = getValues();
    const allFieldsFilled = Object.values(values).every((field) => field);

    if (!allFieldsFilled) {
      alert("error");
      toast.error("Please fill in all fields.");
      return;
    }

    signup(data);
  };

  return (
    <ThemeProvider theme={StandardInputTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="login-form-container shadow">
          <form>
            <h2 className="loginpage_heading">{t("Create Account")}</h2>

            {/* First Name */}
            <div className="input-group">
              <Controller
                control={control}
                name="first_name"
                rules={{ required: "First name is required" }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("First Name")}
                    variant="standard"
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    {...field}
                  />
                )}
              />
            </div>

            {/* Last Name */}
            <div className="input-group">
              <Controller
                control={control}
                name="last_name"
                rules={{ required: "Last name is required" }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("Last Name")}
                    variant="standard"
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                    {...field}
                  />
                )}
              />
            </div>

            {/* Email */}
            <div className="input-group">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("E-postadress")}
                    variant="standard"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...field}
                  />
                )}
              />
            </div>

            {/* Phone */}
            <div className="input-group">
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: "Phone number is required",
                  pattern: {
                    value: /^\d+$/,
                    message: "Phone number must be a number",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("phone")}
                    variant="standard"
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    {...field}
                  />
                )}
              />
            </div>

            {/* Date of Birth with age restriction */}
            <div className="input-group">
              <Controller
                control={control}
                name="date_of_birth"
                rules={{
                  required: "Date of birth is required",
                  validate: (value) =>
                    dayjs(value).isBefore(maxDate) ||
                    "You must be at least 13 years old",
                }}
                render={({ field }) => (
                  <DatePicker
                    label={t("Date of Birth")}
                    maxDate={maxDate}
                    format="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        error={!!errors.date_of_birth}
                        helperText={errors.date_of_birth?.message}
                        {...params}
                      />
                    )}
                    onChange={(date) => field.onChange(date)}
                    value={field.value || null}
                  />
                )}
              />
            </div>

            {/* Password */}
            <div className="input-group">
              <Controller
                control={control}
                name="password"
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel>{t("password")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </div>

            {/* Confirm Password */}
            <div className="input-group">
              <Controller
                control={control}
                name="confirm_password"
                rules={{ required: "Please confirm your password" }}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel>{t("Confirm Password")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </div>

            {/* Submit Button */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I approve Swedebuy's{" "}
                  <Link
                    href="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1876D1", textDecoration: "underline" }}
                  >
                    terms and conditions
                  </Link>{" "}
                  of purchase and{" "}
                  <Link
                    href="/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    style={{ textDecoration: "underline", color: "#1876D1" }}
                  >
                    privacy policy
                  </Link>{" "}
                  (required).
                </Typography>
              }
            />
            <hr className="my-3 mx-2" />

            <p>
              {t("DoReadyAccount")}{" "}
              <Link to="/auth/login" className="signin_link">
                {t("Login")}
              </Link>
              <div className="my-2">
                <button
                  type="button"
                  className="btn btn-login"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("Create Account")}
                </button>
              </div>
            </p>
          </form>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default SignupForm;
