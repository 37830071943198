import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_BASE_URL}order/`;

export const orderApiService = createApi({
    reducerPath: 'orderApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().authSlice; // Destructuring 'token' from authSlice state

            headers.set('Authorization', `Bearer ${token}`); // Setting the Authorization header
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (body) => {


                return {
                    url: 'create',
                    method: 'POST',
                    body,
                };
            },
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Log server response
                } catch (error) {
                    // Log any error
                }
            },
        }),
        getSingleOrder: builder.query({
            query: ({ user_id }) => {
                return user_id ? `get?user_id=${user_id}` : `get?user_id=0`; // Default to 0 if user_id is not provided
            },
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Log server response
           
                } catch (error) {
                    // Log any error
                    console.error('Error fetching single order:', error);
                }
            },
            keepUnusedDataFor: 0, // Do not cache the data
            refetchOnMountOrArgChange: true, // Refetch data on mount or when arguments change
        }),


        returnOrder: builder.mutation({
            query: (body) => {

                return {
                    url: 'return', // Ensure this matches your API endpoint
                    method: 'POST',
                    body,
                };
            },
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Log server response
                } catch (error) {
                    // Log any error
                }
            },
        }),
        getReturnedProducts: builder.query({
            query: ({ user_id }) => {
                if (user_id) {
                    return `getReturnedProducts?user_id=${user_id}`; // Ensure the endpoint matches your API
                }
                return ''; // Handle cases where user_id is not provided
            },
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Log server response or handle data as needed
                } catch (error) {
                    // Log any error
                }
            },
            refetchOnMountOrArgChange: true,  // Refetch every time the component mounts
            keepUnusedDataFor: 0,  // Remove data immediately when there are no subscribers
        }),
        
        checkUserOrders: builder.mutation({
            query: (body) => {

                return {
                    url: 'checkUserOrders', // Ensure this matches your API endpoint
                    method: 'POST',
                    body,
                };
            },
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Log or handle the server response as needed

                } catch (error) {
                    // Log or handle any error that occurred during the request
                    console.error("Error fetching user orders:", error);
                }
            },
        }),
        getTracking: builder.query({
            query: (orderId) => {
                if (orderId) {
                    return `get-tracking/${orderId}`; // Use userId as a query parameter
                }
                return ''; // Return an empty string or handle cases where userId is not provided
            },
        }),

    }),
});

export const {
    useCreateOrderMutation,
    useGetSingleOrderQuery,
    useReturnOrderMutation,
    useGetReturnedProductsQuery,
    useCheckUserOrdersMutation,
    useGetTrackingQuery,
} = orderApiService;
